@import url(https://fonts.googleapis.com/css?family=Nunito:300,400&display=swap);
@import url(https://fonts.googleapis.com/css?family=Oswald:300,400,700);
html {
	overflow: -moz-scrollbars-none !important;
	max-width: 100%;
	overflow-x: hidden;

}

body {
  font-family: 'Work Sans', sans-serif;
  font-family: europa;
  background-color:  white;
	font-size: 16px;
	scrollbar-width: none !important;
  margin:0px;
  overflow-x: hidden;
	-ms-overflow-style: none;
}
body p{
  line-height: 33px;
}

/*----------------------------NAV BAR-----------------------------*/

.navbar {
  margin: 0;
  width: 100%;
  background-color: white;
	color: black;
  position: fixed;
  z-index: 500;
	top: 0;
	transition: top 0.3s;
	display: inline-block;
	list-style: none;
	padding-right: 1em;
	box-shadow: 0px 0px 7px 4px rgba(0, 0, 0, 0.5);
	height: 6rem;
}


.navbar-left img{
  width: 160px;
  height: 76px;
	padding: 1rem 0rem 0rem 1rem;
}
.navbar-left{
	float: left;
}

.navbar-right{
	float: right;
	padding: 1.3rem 1rem 0rem 0rem;
}

.navbar-right{
	font-family: 'Poppins', sans-serif;
}
.fa-bars {
  color: black;
}

.menu-icon {
  display: none;
}

@media screen and (min-width: 960px) {

  .nav-links {
    color: black;
    display: grid;
    text-decoration: none;
    padding: 0rem 0.7rem;

  }

  .nav-links:hover {
    border-bottom: none;
    color: #B8DDFF;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    transition: all 0.2s ease-out;
  }


  .nav-menu {
    display: inline-block;
    grid-gap: 10px;
    list-style: none;
    margin-right: 2rem;
    text-align: center;
    margin: 0;
    padding: 0;
  }

  .nav-menu li{
    display: inline-block;
    font-size: 17px;
    padding: 16px;
  }


}
@media screen and (max-width: 960px) {

  .nav-menu {
    padding-left: 0;
    display: flex;
    list-style: none;
    flex-direction: column;
    width: 0%;
    height: auto;
    position: absolute;
    top: 70px;
    opacity: 1;
    right:0%;
    left:100%;
    transition: all 0.5s ease;
    }

  .nav-menu.active {
    background: transparent;
    opacity: 1;
    transition: all 0.5s ease;
    list-style: none;
    height: auto;
    overflow: hidden;
    width: 100%;
    left:0%;
  }

  .nav-links {
    display:flex;
    background-color: white;
    font-size: 1.2rem;
    font-weight: lighter;
    color: black;
    text-decoration: none;
    padding: 1.5rem;
    justify-content: center;
  }

  .nav-links:hover {
    color: #B8DDFF;
    -webkit-transform: scaleY(1.1);
            transform: scaleY(1.1);
    transition: 0.3s;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    -webkit-transform: translate(-100%, 50%);
            transform: translate(-100%, 50%);
    font-size: 1.8rem;
    padding: 0.5rem 1.5rem 0rem 0rem;
  }

  .fa-times {
    color: black;
    font-size: 2rem;
  }
}

@media screen and (max-width: 500px) {

	    .nav-links {
	      font-size: 16px;
	    }
}

/*--------------------------LANDING PAGE--------------------------*/

#myVideo{
	min-height: 100%;
	min-width:100%;
	position:absolute;
	bottom:0;
	left:0;
 	pointer-events: none;
	z-index:0;

}
.overlay {
    position:absolute;
    top:0;
    left:0;
    z-index:1;

}

.landing {
	height:51rem;
	width: 100%;
	background-image: url(/static/media/Home.3702df11.png);
	background-size: cover;
	background-repeat: no-repeat;
	color: white;
}

.landing h2 {
	font-size: 100px;
  padding: 9.5rem 0rem 0rem 3rem;
  font-weight: 500;
	line-height: 80px;
	width: 70%;
  margin: 0;
	font-family: 'Poppins', sans-serif;
}
.landing h3 {
	font-size: 40px;
  padding: 0rem 0rem 5rem 3rem;
  font-weight: 500;
	line-height: 70px;
	width: 70%;
  margin: 0;
	font-family: 'Poppins', sans-serif;
}

.landing p{
  font-size: 20px;
  font-weight: 200;
  padding: 3rem 3rem 0rem 3rem;
  margin:0;
}

.button1 {
	cursor:pointer;
	border:none;
  margin: 2rem 50px;
	padding:0.8rem 2rem 0.8rem 2rem;
	font-size:15px;
  font-weight: 500;
  width: 20rem;
  background-color: white;
  color: black;
  border: 2px solid white;
  border-radius: 1px;
  font-family: 'Work Sans', sans-serif;
}

.button1:hover {
  color: black;
  background-color: transparent;
	transition:background-color 0.3s ease-in-out;
}

  .info{
    width:55rem;
   max-width:55rem;
  }


@media only screen and (max-width: 768px) {
  .landing {
    background-size: 100rem;
    width: auto;
    height: 45rem;
  }

  .landing p{
    font-size: calc(10px + (50 - 35) * ((100vw - 300px) / (1600 - 300)));
  	font-weight: 400;
  	padding: 0rem 2rem 0rem 3rem;
  }

  .info button{
    padding-left: 2rem;
  }

  .button1 {
  	font-size:12px;
  }
}

@media only screen and (max-width: 500px) {
  .landing {
    height: 40rem;
    width: auto;
  }

	.landing h2 {
		font-size: 57px;
	  padding: 9.5rem 0rem 0rem 1.5rem;
	  font-weight: 500;
		line-height: 50px;
		width: 70%;
	  margin: 0;
		font-family: 'Poppins', sans-serif;
	}
	.landing h3 {
		font-size: 30px;
	  padding: 1rem 0rem 6rem 1.5rem;
	  font-weight: 500;
		line-height: 30px;
		width: 100%;
	  margin: 0;
		font-family: 'Poppins', sans-serif;
	}

  .landing p{
    font-size: 14px;
  	font-weight: 200;
  	padding: 1rem 3rem 0rem 1.5rem;
    margin:0;
    line-height: 1.3rem;
  }

  .info button{
    padding-left: 2rem;
  }

  .info{
    padding-left: 0rem;
  }
  .button1 {
    cursor:pointer;
    border:none;
    margin: 2rem 0px 0 1.5rem;
    padding:0.8rem 2rem 0.8rem 2rem;
    font-size:15px;
    width: 15rem;
    background-color: white;
    color: black;
    border: 2px solid white;
    border-radius: 1px;
  }
  
}


@media only screen and (max-width: 300px) {

	.landing h2 {
		font-size: 40px;
	  padding: 9.5rem 0rem 0rem 2rem;
	  font-weight: 500;
		line-height: 40px;
		width: 70%;
	  margin: 0;
		font-family: 'Poppins', sans-serif;
	}
	.landing h3 {
		font-size: 20px;
	  padding: 0rem 0rem 5rem 2rem;
	  font-weight: 500;
		line-height: 50px;
		width: 70%;
	  margin: 0;
		font-family: 'Poppins', sans-serif;
	}

  .landing p{
    font-size: 14px;
  	font-weight: 200;
  	padding: 1rem 3rem 0rem 2rem;
    margin:0;
    line-height: 1.3rem;
		word-break: break-all;
		width: 20%;

  }

  .info button{
    padding-left: 2rem;
  }

  .button1 {
  	font-size:12px;
		padding-left: 0rem;

  }
}



/*----------------------------------------------------------------*/

/*----------------------------ABOUT US----------------------------*/

.about-section {
  display: inline-flex;
	flex-direction: column;
	min-height: 65vh;
  background-color: white;
  color: black;
  align-content: flex-start;
  text-align: left;
  margin:0;
  padding:0;
  padding-bottom: 100px;
}

.about-section h4 {
	font-size: calc(30px + (60 - 30) * ((100vw - 300px) / (1600 - 300)));
	font-weight: 200;
	padding: 2rem 5rem 2rem 5rem;
  margin-bottom: 0;
}

.about-section p {
  font-size: calc(10px + (60 - 40) * ((100vw - 300px) / (2000 - 300)));
	font-weight: 100;
	padding: 0rem 5rem 0rem 5rem;
  font-size: 20px;
  line-height: 33px;
}

.about-split{
  display:flex;
  resize: none;
}
.about-image{
  height: 30rem;
  width: 25rem;
  padding:7rem 6rem 0rem 1rem;
  margin:0;
}

.abt-button {
  padding: 0rem 0rem 0rem 2rem;

}

.about-button{
  cursor:pointer;
	border:none;
  margin: 2rem 50px;
	padding:0.8rem 2rem 0.8rem 2rem;
	font-size:15px;
  width: 15rem;
  background-color: rgb(210, 210, 210);
  color: rgb(56, 52, 52);
  border: 2px solid  rgb(210, 210, 210);
  border-radius: 1px;
  font-family: europa;

}

.arrow-image{
size: 20;
}

@media(max-width:950px) {

  .about-split{
    display:block;
    resize: none;
  }
  .about-section p {
    padding: 1rem 4rem 0rem 5rem;
    text-align: left;
    font-weight: 100;
    line-height: 25px;
    font-size: 15px;
  }
  .about-image{
    padding: 3rem 5rem 0rem 5rem;
    align-items: center;
    height: 27rem;
    width: 24rem;
  }
  .button2{
    font-size: 12px;
  }

}
@media only screen and (max-width: 500px) {

  .about-section{
    display:block;
    text-align: center;
    margin: 0;
    padding: 0;
    width: inherit;
    padding-bottom: 3rem;
  }

  .about-split{
    display:block;
    text-align: center;
  }

  .about-section h4 {
  	padding: 3rem 2rem 0.5rem 2rem;
    margin:0;
    font-size: 30px;
    font-weight: 100;
  }
  .about-section p {
    padding: 1rem 2rem 0rem 2rem;
    text-align: center;
    line-height: 25px;
    font-size: 14px;
    font-weight: 100;
  }

  .about-image {
    padding: 0rem 2rem 0rem 2rem;
    align-items: center;
  }

  .button2 {
      text-decoration: none;
      font-size: 12px;
      align-items: center;
      padding:0.8rem 2rem 0.8rem 2rem;
      margin: 1.5rem 0rem;
      width: 15rem;
      font-family: europa;

    }
}

@media only screen and (max-width: 400px) {

    .about-section{
      display:block;
      text-align: center;
      margin: 0;
      padding: 0;
      width: inherit;
      padding-bottom: 3rem;
    }

    .about-split{
      display:block;
      text-align: center;
    }

    .about-section h4 {
    	padding: 3rem 2rem 0.5rem 2rem;
      margin:0;
      font-size: 26px;
      font-weight: 100;
    }
    .about-section p {
      padding: 1rem 2rem 0rem 2rem;
      text-align: center;
      line-height: 21px;
      font-size: 11px;
      font-weight: 100;
    }

    .about-image {
      height: 20rem;
      width: 17rem;
		  padding:1rem 2rem 0rem 2rem;
		  margin:0;
    }

    .button2 {
        text-decoration: none;
        font-size: 11px;
        align-items: center;
        padding:0.8rem 2rem 0.8rem 2rem;
        margin: 1.5rem 0rem;
        width: 13rem;

      }
  }


/*---------------------------SERVICES HOME PAGE---------------------------*/

.service {
  margin: 0;
  background-color: white;
}
.service-text{
	display: flex;
	padding: 0rem 0 2rem 0;
}

.service-text h4 {
	width: 100%;
  text-align: center;
	font-size: 40px;
	font-weight: 200;
	padding: 0rem 1rem 0rem 0rem;
	resize:none;
  margin: 0;
}

.service-text p {
  text-align: left;
	font-size: 16px;
	font-weight: 200;
	padding: 0rem 8rem 3rem 3rem;
  resize:none;
  margin: auto;
}

.container{
	padding: 0rem 0rem 3rem 0rem;
	resize:none;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: flex-start;
}

.container h4{
	resize:none;
	font-size: 30px;
	font-weight: 200;
	padding: 2rem 2rem 0.5rem 2rem;
	resize:none;
	margin: 0;
}

.container:hover p{
	line-height:26px;
	font-size: 15px;
	font-weight: 300;
	padding: 0rem 1rem 0rem 1rem;
	resize: none;
	text-align: left;
}
.pre:hover h4, .pre:hover p{
	visibility: visible;
}
.present:hover h4, .present:hover p{
	visibility: visible;
}
.post:hover h4, .post:hover p{
	visibility: visible;
}

.item img{
	width: 100%;
	margin:0;
	position:absolute;
	visibility: visible;
	display: flex;
	justify-content: center;
	align-items: center;
}

.item:hover img{
	position:none;
	visibility: hidden;
}

.item {
  text-align: center;
  border-radius: 0px;
  border:2.3px solid rgba(0, 0, 0, 0.04);
  overflow: hidden;
	display:flex;
	resize: none;
	padding: 0;
	display :inline-block;
	background: white;
	width: 340px;
	height: 380px;
	margin :10px;

}

.item:hover{
  background: #F0EFED;
  box-shadow: 0 0 11px rgba(33,33,33,.2);
  transition: all 0.5s ease 0s;
  -webkit-box-shadow:0 0px 25px rgba(0, 0, 0, 0.10);
  border-bottom: 5px solid #B8DDFF;
}

.service-icons{
	font-size: 30px;
	color: black;
	display:inline-flex;
	justify-content: left;
	align-items: left;
	padding: 2rem 0rem 0rem 3rem;
}

.srvc-button {
  padding: 0rem 0rem 0rem 0rem;
  display: flex;
  justify-content: center
}

.service-button{
  cursor:pointer;
	border:none;
  margin: 2rem 50px;
	color: black;
	padding:0.8rem 2rem 0.8rem 2rem;
	font-size:15px;
  width: 15rem;
  background-color: #B8DDFF;
  color: rgb(56, 52, 52);
  border: 2px solid #B8DDFF;
  border-radius: 1px;
  font-family: europa;

}


@media(min-width:980px) {

  .item p{
    line-height:26px;
    font-size: 15px;
    font-weight: 300;
    padding: 4rem 0rem 1rem 0rem;
    resize: none;
  }
  .item h4{
    text-align: center;
    font-weight: 100;
    padding: 0.5rem 0rem 0.5rem 0rem;
    resize:none;
    margin-top: 0;
    margin-bottom: 0;
		color: #ABD6FE;

  }
}



@media only screen and (max-width: 900px) {
  .service-text h4{
    padding: 3rem 0 0 0;
    font-size: 40px;
  }
	.service-text p{
		padding: 1rem 4rem 1rem 5rem;
	}
	.service-text{
		display: block;
		margin: auto;
		width: 100%;
	}
	.container{
		display: block;
		margin: auto;
	  width: 50%;
	}
  .item{
    padding: 0rem 0rem 0rem 0rem;
    width: 340px;
    height: 380px;
		margin: 0 auto;
  }

  .item p{
    font-size:14px;
    line-height:26px;
    font-weight: 200;
  }

  .item h4 {
  	font-size: calc(20px + (60 - 30) * ((100vw - 300px) / (1600 - 300)));
  	font-weight: 200;
  	padding: 1rem 0rem 1rem 0rem;
		color: #ABD6FE;
  }
}

@media only screen and (max-width: 500px) {
  .service h4{
    padding-bottom: 1rem;
    font-size: 35px;
    padding-top: 0;
  }

  .container{
    width :100%;
    height :100%;
    justify-content: center;
    text-align: center;
  }

  .item p{
    font-size:14px;
    line-height:26px;
    font-weight: 200;
  }

  .service-text p{
		padding: 1rem 2rem 1rem 2rem;
    text-align: center;
	}
  .service {
  	background-color: white;
    align-content: center;
    resize:none;
  }
  .item h4 {
    text-align: center;
  	font-size: calc(20px + (60 - 30) * ((100vw - 300px) / (1600 - 300)));
  	font-weight: 200;
  	padding: 1rem 0rem 1rem 0rem;
    width: auto;
    height: auto;
    resize:none;
    margin: auto;
		color: #ABD6FE;

  }
  .inner-container{
    padding-bottom: 3rem;

  }

}

@media only screen and (max-width: 300px) {
  .service h4{
    padding-bottom: 1rem;
    font-size: 26px;
  }
  .item{
    padding: 1rem 1rem 2rem 1rem;
    resize: none;
    display :inline-block;
  }

  .container{
    width :100%;
    height :100%;
    justify-content: center;
    text-align: center;
  }
  .service-icons{
    font-size: 27px;
  }

  .item p{
    font-size:11px;
    line-height: 20px;
    font-weight: 200;
  }
  .item h4 {
    text-align: center;
  	font-size: calc(20px + (60 - 30) * ((100vw - 300px) / (1600 - 300)));
  	font-weight: 200;
  	padding: 1rem 0rem 1rem 0rem;
  }
}

/*----------------------------------------------------------------*/

/*--------------------------Mid section --------------------------*/

.Mid1 {
  background-image: url(/static/media/Mid1.fbdc9679.jpg);
	height:25rem;
	width: 100%;
	margin-top: 7rem;
	opacity: 0.8;
  background-position: center 55%;
  flex-direction: column;
  color: black;
  align-content: flex-start;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
}

.Mid1 h4 {
	font-size: 30px;
  padding: 8rem 3rem 2rem 3rem;
  font-weight: 300;
  margin: 0;
	text-align: center;
}

/*----------------------------------------------------------------*/

/*----------------------------Testimonials--------------------------*/

.testimonials {
	flex-direction: column;
	min-height: 60vh;
  background-color: white;
  color: white;
  margin:0;
  padding:0;
	display: flex;
}
.testimonials h4 {
	font-weight: 300;
	padding: 2rem 4rem 0rem 5rem;
  font-size: 44px;
  line-height: 40px;
	color: black;
  margin-bottom: 0;
}

.test-container  p {
  font-size: calc(10px + (60 - 40) * ((100vw - 300px) / (2000 - 300)));
	font-weight: 100;
	padding: 0rem 2rem 0rem 3rem;
  font-size: 20px;
  line-height: 33px;
	color: black;
	justify-content: left;
	text-align: left;
}

.test-container h5 {
	font-weight: 300;
	padding: 0rem 4rem 0rem 3rem;
  font-size: 23px;
  line-height: 33px;
	color: black;
	justify-content: left;
	text-align: left;
}
.test-container{
	min-height: 65vh;
	margin-bottom: 4rem;
	display: flex;
}
.test-div{
	min-width: 150px;
  max-width: 410px;
	justify-content: left;
	text-align: left;
}

.test-underline{
	position: relative;
	border: none;
	height: 3px;
	width: 7rem;
	background: #DBEEFF;
	margin-bottom: 3rem;
	margin-left: 5rem;
}

.pic {
  display: flex;
  border: 4px solid white;
	padding-bottom: 1rem;
	justify-content: center;
	text-align: center;
}

.pic img {
  width: 10rem;
  height: 10rem;
}

.test-container {
  position:relative;
	justify-content: center;
	text-align: center;
}

.testimony-pic {
  display: inline-block;
  border: 4px solid white;
  border-radius: 50%;
  box-shadow: 0 0 4px 4px #A7D3FD;
  overflow: hidden;
  z-index: 1;
}
.testimonial-icons{
	font-size: 30px;
	color: black;
	display:inline-flex;
	justify-content: left;
	align-items: left;
	padding: 2rem 0rem 0rem 3rem;

}


.test-button {
  padding: 0rem 0rem 0rem 2rem;
  margin-top: 0;
}

.testimonial-button{
  cursor:pointer;
	border:none;
  margin: 2rem 50px 3rem;
	color: black;
	padding:0.8rem 2rem 0.8rem 2rem;
	font-size:15px;
  width: 15rem;
  background-color:#B8DDFF;
  color: rgb(56, 52, 52);
  border: 2px solid #B8DDFF;
  border-radius: 1px;
  font-family: europa;

}


@media only screen and (max-width: 980px) {
  .test-container{
    width :100%;
    height :100%;
    justify-content: center;
    text-align: center;
  }
}


@media only screen and (max-width: 800px) {

  .testimonials {
    flex-direction: column;
    min-height: 60vh;
    margin:0;
    padding:0;
    justify-content: center;
    text-align: center;
  }

  .test-container{
    width :100%;
    height :100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
  }


  .test-container  p {
    font-size: 18px;
    padding: 0rem 0rem 0rem 0rem;
    font-size: 20px;
    line-height: 33px;
    color: black;
  }

  .test-container h5 {
    font-weight: 300;
    padding: 0rem 0rem 0rem 0rem;
    font-size: 23px;
    line-height: 33px;
    color: black;
  }
}

@media only screen and (max-width: 500px) {

  .testimonials {
    flex-direction: column;
    min-height: 60vh;
  }
  .testimonials h4 {
    font-weight: 300;
    padding: 2rem 4rem 0rem 5rem;
    font-size: 35px;
    line-height: 40px;
    color: black;
  }
  
  .test-container{
    width :100%;
    height :100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
  }
  
  .test-container  p {
    font-size: 18px;
    padding: 0rem 0rem 0rem 0rem;
    font-size: 20px;
    line-height: 30px;
    color: black;
  }
  
  .test-container h5 {
    font-weight: 300;
    padding: 0rem 0rem 2rem 0rem;
    font-size: 25px;
    line-height: 0px;
    color: black;
    justify-content: left;
    text-align: left;
  }
  
  
  }

/*----------------------------------------------------------------*/

/*----------------------------project------------------------------*/

.project {
	display: flex;
	flex-direction: column;
	min-height: 70vh;
	background-color: white;
	color: black;
  padding-bottom: 70px;
  text-align: center;
}

.project h4 {
	font-size: calc(30px + (60 - 30) * ((100vw - 300px) / (1600 - 300)));
	font-weight: 200;
	padding: 0rem 0rem 1rem 0rem;
  margin-bottom: 0;
  margin-top:0;
}

.project p {
	font-weight: 100;
  font-size: 20px;
	padding: 0rem 0rem 2rem 5rem;
  line-height: 33px;
}

.project-split{
	margin: 0;
	padding: 3rem 0rem 0rem 0rem;
	resize:none;
	text-align: center;
}

.project-img{
  height: 350px;
  width: 450px;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  margin:0;
  resize: both;
}

.proj-button {
  padding: 0rem 0rem 0rem 0rem;

}

.project-button{
  cursor:pointer;
	border:none;
  margin: 2rem 50px;
	padding:0.8rem 2rem 0.8rem 2rem;
	font-size:15px;
  width: 15rem;
  background-color:  white;
  color: rgb(56, 52, 52);
  border: 2px solid  black;
  border-radius: 1px;
  font-family: europa;

}
.project-button:hover {
  cursor:pointer;
	border:none;
  margin: 2rem 50px;
	padding:0.8rem 2rem 0.8rem 2rem;
	font-size:15px;
  width: 15rem;
  background-color:  rgb(47, 47, 47);
  color: white;
  border: 2px solid  rgb(47, 47, 47);
  border-radius: 1px;

}

@media(max-width:898px) {


  .project p {
    padding: 0rem 3rem 1rem 5rem;
    text-align: left;
    font-weight: 100;
    line-height: 25px;
    font-size: 15px;
    width:inherit;
  }
}

@media(max-width:750px) {
  .project {
    display: flex;
    flex-direction: column;
    min-height: 70vh;
    background-color: white;
    color: black;
    padding-bottom: 70px;
    text-align: center;
  }
}


@media only screen and (max-width: 500px) {
  .project {

  }

	.project-split .project-img{
    height: 280px;
    width: 300px;
    margin:0;
  }
  .project h4 {
    	padding: 3rem 2rem 0.5rem 2rem;
      margin:0;
      font-size: 27px;
      font-weight: 200;
      text-align: center;

  }
  .project p {
      padding: 1rem 2rem 0rem 2rem;
      font-weight: 100;
      text-align: center;
      line-height: 25px;
      font-size: 14px;
      width:inherit;
      white-space: pre-wrap;
      word-wrap: break-word;
    }
}


/*----------------------------------------------------------------------*/

/*----------------------------project Page------------------------------*/

.projects-page {
	display: flex;
	flex-direction: column;
	background-color: white;
	color: black;
  padding-top: 6rem;
  text-align: center;
  font-family: europa;
}

.projects-page h4 {
	font-size: 50px;
	font-weight: 200;
	padding: 2rem 0rem 0rem 0rem;
  margin-bottom: 1rem;
}

.projects-page p {
	font-weight: 100;
  font-size: 18px;
	padding: 0rem 8rem 0rem 8rem;
  line-height: 33px;
}

.projectpage-button{
  cursor:pointer;
	border:none;
  margin: 4rem 50px;
	color: black;
	padding:0.8rem 2rem 0.8rem 2rem;
	font-size:15px;
  width: 15rem;
  background-color:  white;
  color: black;
  border: 2px solid  black;
  border-radius: 1px;
  font-family: europa;

}
.projectpage-button:hover{
  background-color:  black;
  color: white;
  border: 2px solid  black;
  border-radius: 1px;
  font-family: europa;

}


.projectpage-split{
	padding: 0rem 0rem 3rem 0rem;
	text-align: center;
  display:flex;
	flex-direction:row;
  flex-wrap: wrap;
  justify-content: center;
}


.projectspage-img{
  height: 260px;
  width: 350px;
  padding: 0rem 0.25rem 0rem 0.25rem;
  display: block;
}

.project-overlay {
  position:absolute;
  top:0;
  left:0;
  z-index:1;

}

.project-overlay {
  opacity: 0;
  height: 300px;
  width: 430px;
  transition: .5s ease;
  background-color: transparent;
}

.project-text {
  color: black;
  font-size: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.editorial-container {
  position: relative;
  padding: 0rem 0rem 0.5rem 0rem;
}

.project-inside-div{
  position: relative;
  padding: 0rem 0rem 0.5rem 0rem;
}

.editorial-container:hover .project-overlay {
  opacity: 1;
}

.editorial-container:hover .projectspage-img {
  opacity: 0.3;
}



@media(max-width:898px) {


  .projects-page p {
    padding: 0rem 3rem 0rem 5rem;
    text-align: left;
    font-weight: 100;
    line-height: 25px;
    font-size: 15px;
    width:inherit;
  }
}
@media only screen and (max-width: 500px) {


  .projects-page h4 {
    	padding: 3rem 2rem 0.5rem 2rem;
      margin:0;
      font-size: 30px;
      font-weight: 200;
      text-align: center;

  }
  .projects-page p {
      padding: 1rem 2rem 0rem 2rem;
      font-weight: 100;
      text-align: center;
      line-height: 25px;
      font-size: 14px;
      width:inherit;
      white-space: pre-wrap;
      word-wrap: break-word;
    }
}


/*----------------------------------------------------------------------------*/

/*---------------------------- Project Template Page ------------------------------*/


.project-template{
	display: flex;
	flex-direction: column;
	background-color: white;
	color: black;
  padding-top: 7rem;
  padding-bottom: 7rem;
  text-align: center;
  font-family: europa;
}

.project-template h4 {
	font-size: 50px;
	font-weight: 200;
	padding: 0rem 0rem 0rem 0rem;
  margin-bottom: 0;
}

.project-template h5 {
	font-size: 25px;
	font-weight: 200;
  margin: 0;
  padding: 1rem 0rem 1rem 0rem;
  font-family: europa;
}
.project-template p {
	font-weight: 100;
  font-size: 18px;
	padding: 0rem 8rem 0rem 8rem;
  line-height: 33px;
  font-family: 'Work Sans', sans-serif;
}

.project-split{

	padding: 0rem 0rem 0rem 0rem;
	text-align: center;
  display:flex;
	flex-direction:row;
  flex-wrap: wrap;
  justify-content: center;
}

.main-project-img{
  height: 380px;
  margin:0;
  margin: auto;
  width: 90%;
  max-width: 100%;
  object-fit: cover;
  object-position: 5px 74%;
}


.project-div{
	padding: 0rem 0rem 5rem 0rem;
	resize:none;
	text-align: center;
	display:flex;
	justify-content: center;
	align-items: center;
}

.project-div h4 {
	font-size: 30px;
	font-weight: 200;
	padding: 0rem 0rem 0rem 0rem;
  margin-bottom: 0;
}

.project-underline{
  position: relative;
  border: none;
  height: 1px;
  width: 40rem;
  margin-left: auto;
  margin-right: auto;
  background: black;
  margin-bottom: 40px;
  margin-top: 2rem;
}

.project-desc{
  display: inline-flex;
  padding:0;
  margin:0;
  text-align: left;
}

.project-desc-text{
  padding:0;
  margin:0;
}

.project-desc img{
  height: 400px;
  width: 650px;
  padding: 2rem 0rem 0rem 3rem;
  margin:0;
  resize: both;
  display: block;
  margin: 0;
  position:relative;
  float: left;
}
.project-desc-text h5 {
	font-size: 28px;
	font-weight: 200;
  margin-bottom: 1rem;
  padding: 2rem 6rem 1rem 4rem;
  font-family: europa;
}
.project-desc-text p {
  padding: 0rem 6rem 0rem 4rem;
  font-size: 18px;
  font-family: Europa-Regular;
  font-family: 'Work Sans', sans-serif;

}
.project-desc-text .p1{
  font-family: europa;
  font-weight: 700;
  font-style: normal;
  font-size: 14px;
  letter-spacing: .4em;
  line-height: 2em;
  text-transform: uppercase;
}


@media(max-width:898px) {


  .project-template p {
    padding: 0rem 3rem 1rem 5rem;
    text-align: left;
    font-weight: 100;
    line-height: 25px;
    font-size: 15px;
    width:inherit;
  }
}
@media only screen and (max-width: 500px) {

  .project-template h4 {
    	padding: 3rem 2rem 0.5rem 2rem;
      margin:0;
      font-size: 27px;
      font-weight: 200;
      text-align: center;

  }
  .project-template p {
      padding: 1rem 2rem 0rem 2rem;
      font-weight: 100;
      text-align: center;
      line-height: 25px;
      font-size: 14px;
      width:inherit;
      white-space: pre-wrap;
      word-wrap: break-word;
    }
}


/*----------------------------------------------------------------*/

/*-----------------------------Process cycle----------------------------*/

.process {
	display: flex;
	flex-direction: column;
	background-color: #DBEEFF;
	color: black;
  text-align: left;

}

.process h4 {
	font-size: calc(30px + (60 - 30) * ((100vw - 300px) / (1600 - 300)));
	font-weight: 200;
	padding: 0rem 0rem 2rem 5rem;
  margin-bottom: 0;
}

.process p {
	font-weight: 100;
  font-size: 20px;
	padding: 0rem 0rem 0rem 5rem;
  line-height: 20px;
}

.process-img{
	width: 50%;
   position: absolute;
   right: 0px;
   height: 100%;
}

.process-split{
	display:flex;
}

@media only screen and (max-width: 898px) {
	.process-split{
		display: block;
		margin: auto;
		width: 100%;

	}
	.process-img{
		width: 100%;
	  position:relative;
	  height: 100%;
	}
}

/*----------------------------------------------------------------*/

/*-----------------------------CONTACT----------------------------*/



.contact h4 {
  font-size: calc(20px + (60 - 40) * ((100vw - 300px) / (1600 - 300)));
	font-weight: 600;
	padding: 0rem 3rem 0rem 3rem;
	text-align: left;
}

.contact p {
  font-size: calc(10px + (60 - 40) * ((100vw - 300px) / (2000 - 300)));
	font-weight: 100;
	padding: 0rem 0rem 0rem 0rem;
}

.contact-div{
	padding: 7rem 2rem 0rem 5rem;
  flex-direction: column;
  min-height: 60vh;
  color: black;
  align-content: flex-start;
	background-image: url(/static/media/Contact.f12b92d3.png);
  height: 60rem;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
}
.contact{
  flex-direction: column;
  min-height: 60vh;
  color: black;
  align-content: flex-start;
  padding: 2rem 2rem 0rem 5rem;
	background-image: url(/static/media/Contact.f12b92d3.png);

  height: 40rem;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
}

.contact-split{
  display:flex;
  resize: none;
}
.contact-split-left{
  padding: 2rem 1rem 6rem 1rem;
  display: inline;
  width:50%;
}
.contact-split-left h3{
  color: white;
  font-size: 40px;
  font-weight: 400;
  margin-bottom: 0;
}
.contact-split-left p{
	font-size:15px;
}

.contact-msg{
  padding: 0;
  margin:0;
}

.input-group2 .bottom{
  line-height: 10px;
}
.input-row2{
  color: black;
  display: flex;
  font-size: 15px;
}

#contact input[type="text"], #contact input[type="email"], #contact input[type="tel"], #contact input[type="url"], #contact textarea, #contact button[type="submit"] { font:400 12px/16px "Open Sans", Helvetica, Arial, sans-serif; }

#contact {
  min-height: 60vh;
  text-align: left;
  width: 100%;
	padding:25px;
  padding-top: 5rem;
  resize: none;
}

#contact h3 {
	display: block;
	font-size: 30px;
	font-weight: 400;
}
#contact p {
	margin:5px 0 15px;
	display:block;
	font-size:13px;
  color: black;
}

fieldset {
	border: medium none !important;
	margin: 0 0 10px;
	min-width: 100%;
	padding: 0;
  resize: none;
}

select {
	border: medium none !important;
	margin: 0 0 10px;
	padding: 0;
  resize: none;
  font-size: 12px;

}

#contact select[name="time"]{
  width:72.4%;
	border:1px solid #CCC;
	background-color: #F9F9F9;
	margin:0 0 5px;
	padding:10px;
  resize: none;
  color: #888686;
}

#contact input[type="text"], #contact input[type="email"], #contact input[type="tel"], #contact input[type="url"], select[name="time"],#contact textarea {
	width:70%;
	border:1px solid #CCC;
	background-color: #F9F9F9;
	margin:0 0 5px;
	padding:10px;
  resize: none;
}

#contact input[type="text"]:hover, #contact input[type="email"]:hover, #contact input[type="tel"]:hover, #contact input[type="url"]:hover, #contact textarea:hover {
	transition:border-color 0.3s ease-in-out;
	border:1px solid #AAA;
}

#contact textarea {
	height:100px;
  resize:none;
}

#contact button[type="submit"] {
	cursor:pointer;
  width:73%;
	border:none;
	background:#363636;
	color:#FFF;
	padding:10px;
	font-size:15px;
}

#contact button[type="submit"]:hover {
	background:#B8DDFF;
  color: black;
	transition:background-color 0.3s ease-in-out;
}

#contact button[type="submit"]:active { box-shadow:inset 0 1px 3px rgba(0, 0, 0, 0.5); }

#contact input:focus, #contact textarea:focus {
	outline:0;
	border:1px solid #999;
}
::-webkit-input-placeholder {
 color:#888;
}
:-moz-placeholder {
 color:#888;
}
::-moz-placeholder {
 color:#888;
}
:-ms-input-placeholder {
 color:#888;
}

.div-map{
  display: flex;
  padding: 0rem 4rem 4rem 0rem;
}
.map{
  width:350px;
  height:250px;
}
@media only screen and (max-width: 1250px) {
  #contact select[name="time"]{
    width:73%;
    border:1px solid #CCC;
    background-color: #F9F9F9;
    margin:0 0 5px;
    padding:10px;
    resize: none;
    color: #888686;
  }
}

@media only screen and (max-width: 898px) {
  .contact-split{
    display:block;
    resize: none;
}
  #contact {
    min-height: 60vh;
    text-align: left;
    width: 100%;
    padding: 2rem 0rem 2rem 1rem;
  }
  .contact-split-left{
    padding: 0rem 7rem 0rem 0rem;
  }
  .contact-split-left h3{
    padding: 0rem 0rem 0rem 1rem;
    font-size: 30px;
    font-weight: 400;
  }
  .contact-split-left p{
    font-size:15px;
    padding: 0rem 0rem 0rem 1rem;
  }
  .map{
    width:350px;
    height:250px;
    margin: 1rem 3rem 0rem 1rem;
  }
  #contact select[name="time"]{
    width:72.3%;
    border:1px solid #CCC;
    background-color: #F9F9F9;
    margin:0 0 5px;
    padding:10px;
    resize: none;
    color: #888686;
  }
	.contact{
	  min-height: 60vh;
	  padding: 2rem 0 0rem 2rem;

	}
  .contact-div{
    min-height: 60vh;
	  padding: 7rem 0 0rem 2rem;

  }
  .map{
    width:310px;
    height:230px;
  }
}

@media only screen and (max-width: 680px) {
  #contact select[name="time"]{
    width:73.1%;
    border:1px solid #CCC;
    background-color: #F9F9F9;
    margin:0 0 5px;
    padding:10px;
    resize: none;
    color: #888686;
  }
}


@media only screen and (max-width: 468px) {
  #contact select[name="time"]{
    width:74.5%;
    border:1px solid #CCC;
    background-color: #F9F9F9;
    margin:0 0 5px;
    padding:10px;
    resize: none;
    color: #888686;
  }

#contact button[type="submit"] {
	cursor:pointer;
  width:74.5%;
	border:none;
	background:#363636;
	color:#FFF;
	padding:10px;
	font-size:15px;
}
}

@media only screen and (max-width: 400px) {
  #contact select[name="time"]{
    width:75.4%;
  }

#contact button[type="submit"] {
	cursor:pointer;
  width:75.4%;
}
}

/*------------------------------FOOTER----------------------------*/

.footer {
	text-align: center;
	background-color: white;
	color: black;
	line-height: 70px;
	height: 70px;
	text-align: center;
	box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.5);

}


/*----------------------------------------------------------------*/

/*----------------------------SCROLL------------------------------*/

.MuiFab-secondary {
	background-color: blue !important;
	color: white !important;
}

/*----------------------------------------------------------------*/


/*----------------------------ABOUT PAGE CSS------------------------------*/

/*----------- About Page: Section 1 ------------*/

.aboutpage-CN {
	display: flex;
	flex-direction: column;
	background-color: white;
	color: black;
  resize: none;
  padding-top: 8rem;
}

.abt-back h4 {
	font-size: 34px;
	font-weight: 300;
	padding: 0rem 0rem 0rem 0rem;
	text-align: left;
  margin-bottom: 1rem;
}

.abt-back h2 {
	font-size: 25px;
	font-weight: 300;
	padding: 0rem 0rem 0rem 0rem;
	text-align: left;
  margin:0;
  color: #B8DDFF;
}
.aboutpage-CN h1 {
	font-size: 50px;
	font-weight: 300;
	padding: 4rem 0rem 4rem 0rem;
	text-align: center;
  margin:0;
}
.aboutpage-CN p {
	font-size: 16px;
	font-weight: 100;
	padding: 0rem 3rem 0rem 0rem;
  line-height: 33px;
  font-family: 'Raleway', sans-serif;

}

.aboutpage-text{
  padding: 0rem 2rem 0rem 1.5rem;
  text-align: left;
  margin-top: 0;
}

.aboutpage-text h4 {
	font-size: 40px;
  height: 10px;
  text-align: left;
  margin-top: 0;
  padding: 0rem 0rem 0rem 2.5rem;
}

.aboutpage-text p {
  width: 400px;
  resize: none;
  padding: 0rem 0rem 0rem 2.5rem;
  margin-top: 0;
  text-align: left;
  font-size: 16px;
	font-weight: 100;
  line-height: 33px;
  font-family: 'Raleway', sans-serif;

}
.aboutpage-img-div{
  display: inline-flex;
}

.about_page_architect{
  height: 500px;
  width: 800px;
  resize: none;
  padding: 3rem 3rem 4rem 4rem;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}


.abt-back-2 h4 {
	font-size: 34px;
	font-weight: 300;
	padding: 0rem 0rem 0rem 3rem;
	text-align: left;
  margin-bottom: 1rem;
}

.abt-back-2 h2 {
	font-size: 25px;
	font-weight: 300;
	padding: 0rem 0rem 0rem 3rem;
	text-align: left;
  margin:0;
  color: #B8DDFF;
}

.abt-back-2  p {
	font-size: 16px;
	font-weight: 100;
	padding: 0rem 3rem 0rem 3rem;
  line-height: 33px;
  font-family: 'Raleway', sans-serif;
}
.about_page_img_offer{
  height: 380px;
  width: 570px;
  resize: none;
  padding: 3rem 3rem 2rem 0rem;
  margin:0;

}

/*----------- About Page: Section 2 ------------*/

.abt-back-values{
  background-color: #ede8de;
  color: black;
  padding: 0rem 2rem 2.5rem 2rem;
  text-align: center;
  margin-left: auto;
  margin-right: auto;

}
.abt-back-values h4{
  padding: 3rem 0rem 0.7rem 0rem;
  margin-bottom: 0;
  text-align: center;
	font-size: 40px;
	font-weight: 300;
}
.underline{
  position: relative;
  border: none;
  height: 2px;
  width: 25rem;
  background: black;
  margin-bottom: 50px;
}
.abt-p{
  display: flex;
  resize: none;
}

.abt-p h5{
  padding: 1rem 3rem 0rem 3rem;
  font-size: 20px;
  text-align: center;
}

.abt-p p{
  padding: 1rem 3rem 1rem 3rem;
  font-size: 20px;
  text-align: center;
}

.about_page_img1{
  height: 380px;
  width: 570px;
  resize: none;
  padding: 3rem 3rem 0rem 3rem;

}
/*----------- About Page: Section 3 ------------*/

.about-img{
  height: 300px;
  width: 300px;
  resize: none;
}

.team{
	padding: 0rem 0rem 6rem 0rem;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.team-title{
  font-size: 40px;
	font-weight: 300;
	padding: 0rem 0rem 2rem 0rem;
  text-align: center;
  margin-bottom: 0;
}

.aboutpage-split{
  display:flex;
  font-size: 30px;
	font-weight: 600;
	padding: 3rem 0rem 0rem 2rem;
  text-align: left;
}

.aboutpage-text .name{
	font-size: 30px;
  height: 10px;
  text-align: left;
  margin-top: 0;
  padding: 0rem 0rem 0rem 2.5rem;
  color: #C8BFAB;
}

.aboutpage-text .title{
	font-size: 20px;
  height: 10px;
  text-align: left;
  margin-top: 0;
  padding: 0rem 0rem 0rem 2.5rem;
}


@media only screen and (max-width: 768px) {

  .aboutpage-split{
    display:block;
    align-items: center;
    padding-left: 20px;
  }
  .aboutpage-split img{
    height: 50%;
    width: 50%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .aboutpage-text{
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .abt-back p {
  	padding: 1rem 3rem 0rem 3rem;
    font-size: 18px;
  }
  .aboutpage-CN h4 {
    font-size: calc(30px + (60 - 40) * ((100vw - 300px) / (1600 - 300)));
    padding: 0rem 0rem 1rem 0rem;
  	font-weight: 300;
    margin-bottom: 0;
  }
  .abt-back-values p{
    padding: 1rem 0rem 0rem 0rem;
    font-size: 18px;
  }
  .aboutpage-text p{
    padding: 1rem 0rem 0rem 0rem;
    font-size: 18px;
  }
  .abt-p{
    display:inline;
    resize: none;
    text-align: center;
  }
  .abt-back-values{
  	padding: 0rem 3rem 4rem 3rem;
  }
}

@media only screen and (max-width: 500px) {

    .aboutpage-split{
      display:block;
      align-items: center;
    }
    .aboutpage-split img{
      height: 20rem;
      width: 20rem;
      justify-content: center;
      padding: 0rem 0rem 3rem 0rem;

    }
    .aboutpage-text p {
      font-size: 14px;
      width: 305px;
      resize: none;
      padding: 2rem 0rem 0rem 0rem;
      margin-top: 0;
      text-align: left;
    }

    .aboutpage-CN p{
      font-size: 14px;
    }
}


@media only screen and (max-width: 300px) {

      .aboutpage-CN p{
        font-size: 11px;
        line-height: 20px;
        padding: 1rem 0rem 0rem 0rem;
      }
      .aboutpage-CN h4{
        font-size: 26px;
      }

      .aboutpage-split{
        display:block;
        justify-content: center;
      }


    .aboutpage-split img{
        height: 15rem;
        width: 15rem;
        justify-content: center;
        padding: 0rem 0rem 2rem 0rem;
        margin: 0;
      }

      .abt-back p {
      	padding: 1rem 2rem 0rem 2rem;
        font-size: 11px;
        margin: 0;

      }
      .abt-p h5{
        justify-content: center;
        padding: 0;

      }


      .aboutpage-text h4{
        text-align: center;
        margin: 0;
      }
      .aboutpage-text p{
        font-size: 11px;
        line-height: 20px;
        padding: 1rem 0rem 0rem 0rem;
        width: 210px;
        margin-top: 0;
        text-align: center;
      }


    }



/*----------------------------CLIENT PAGE CSS------------------------------*/



.Client {
	display: flex;
	flex-direction: column;
	min-height: 60vh;
	background-color: white;
	color: black;
  align-content: flex-start;
}

.Client h4 {
	font-size: calc(20px + (60 - 40) * ((100vw - 300px) / (1600 - 300)));
	font-weight: 600;
	padding: 0rem 3rem 0rem 3rem;
	text-align: left;
}

.Client p {
	font-size: calc(10px + (60 - 40) * ((100vw - 300px) / (2000 - 300)));
	font-weight: 100;
	padding: 0rem 3rem 0rem 3rem;
}

.list-view {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(255,255,255,1);
    z-index: 1;
}


/*----------------------------CLIENT PAGE CSS------------------------------*/

.Service-CN {
	display: flex;
	flex-direction: column;
  align-content: flex-start;
  text-align: center;
  padding: 7rem 5rem 3rem 5rem;
}
.Service-CN h4 {
	font-size: calc(30px + (60 - 40) * ((100vw - 300px) / (1600 - 300)));
  padding: 1rem 0rem 1rem 0rem;
	font-weight: 300;
  background-color: white;
  color:black;
  margin-bottom: 0;
}

.Service-CN p {
	font-size: calc(8px + (60 - 40) * ((100vw - 300px) / (2000 - 300)));
	font-weight: 80;
  font-style: italic;
  padding: 1rem 5rem 1rem 5rem;
}
.service-split-img{
  height: 300px;
  width: 400px;
  padding: 0rem 2rem 0rem 2rem;
}

.service-split{
  display:flex;
  color: black;
  resize: none;
  padding: 1rem 0rem 0rem 0rem;
  margin-right: 0;
}

.section1-CN {
  display: flex;
	flex-direction: column;
  background-color: white;
  color: black;
  align-content: flex-start;
  padding: 1rem 0rem 2rem 0rem;
  margin-top: 0;
}
.service-split h4{
  font-size: 30px;
	font-weight: 300;
  color: black;
  padding: 0rem 0rem 0rem 3rem;
  text-align: left;
  margin-top: 0;
}
.service-split p {
  font-size:  20px;
	font-weight: 100;
	padding: 0rem 3rem 0rem 3rem;
}

.section2-CN {
	display: flex;
	flex-direction: column;
  background-color: white;
  color: black;
  align-content: flex-start;
  padding: 1rem 0rem 2rem 0rem;
  margin-top: 0;
}

.section3-CN {
	display: flex;
	flex-direction: column;
	min-height: 60vh;
	background-color: white;
	color: black;
  align-content: flex-start;
  padding: 1rem 0rem 3rem 0rem;
}

@media only screen and (max-width: 828px) {
  .Service-CN{
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    text-align: center;
    padding: 7rem 2rem 0rem 2rem;
  }
  .Service-CN h4 {
  	font-size: calc(30px + (60 - 40) * ((100vw - 300px) / (1600 - 300)));
    padding: 1rem 0rem 1rem 0rem;
  	font-weight: 300;
    background-color: white;
    color:black;
    margin-bottom: 0;
  }

  .Service-CN p {
  	font-size: 18px;
  	font-weight: 80;
    padding: 1rem 0rem 1rem 0rem;
  }
  .service-split{
    display:block;
  }
  .service-split h4{
    text-align: center;
    padding: 3rem 2rem 0rem 2rem;
  }
  .service-split p{
    text-align: center;
    font-size: 18px;
    padding: 0rem 4rem 0rem 4rem;
  }
  .service-split img{
    height: 300px;
    width: 400px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

}
@media only screen and (max-width: 500px) {
  .Service-CN{
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    text-align: center;
    padding: 7rem 2rem 0rem 2rem;
  }
  .Service-CN h4 {
  	font-size: calc(30px + (60 - 40) * ((100vw - 300px) / (1600 - 300)));
    padding: 1rem 0rem 1rem 0rem;
  	font-weight: 300;
    background-color: white;
    color:black;
    margin-bottom: 0;
  }

  .Service-CN p {
  	font-size: 14px;
  	font-weight: 80px;
    font-style: italic;
    padding: 1rem 0rem 1rem 0rem;
  }
  .service-split{
    display:block;
  }
  .service-split h4{
    text-align: center;
    padding: 3rem 2rem 0rem 2rem;
  }
  .service-split p{
    text-align: center;
    font-size: 14px;
    padding: 0rem 2rem 0rem 2rem;
  }
  .service-split img{
    height: 300px;
    width: 200px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 300px) {
  .Service-CN{
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    text-align: center;
    padding: 7rem 2rem 0rem 2rem;
  }
  .Service-CN h4 {
  	font-size: 26px;
    padding: 1rem 0rem 1rem 0rem;
  	font-weight: 300;
    background-color: white;
    color:black;
    margin-bottom: 0;
  }

  .Service-CN p {
  	font-size: 11px;
  	font-weight: 20px;
    font-style: italic;
    line-height: 20px;
    padding: 1rem 0rem 0rem 0rem;
  }
  .service-split{
    display:block;
    padding: 0rem 0rem 0rem 0rem;
    margin: 0;

  }
  .service-split h4{
    text-align: center;
    padding: 3rem 2rem 0rem 2rem;
  }
  .service-split p{
    text-align: center;
    font-size: 11px;
    line-height: 20px;
    padding: 0rem 2rem 0rem 2rem;
  }
  .service-split img{
    height: 300px;
    width: 200px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
    margin-top: 0;
  }
}
/*----------------------------FAQ PAGE CSS------------------------------*/

.FAQ-CN {
	display: flex;
	flex-direction: column;
	min-height: 60vh;
	background-color: white;
	color: black;
  align-content: flex-start;
}

.FAQ-CN h3 {
	font-size: calc(20px + (60 - 40) * ((100vw - 300px) / (1600 - 300)));
	font-weight: 600;
	padding: 0rem 3rem 0rem 3rem;
	text-align: left;
}

.FAQ-CN h4 {
	font-size: calc(10px + (60 - 40) * ((100vw - 300px) / (1600 - 300)));
	font-weight: 600;
	padding: 0rem 3rem 0rem 3rem;
	text-align: left;
}

.FAQ-CN p {
	font-size: calc(10px + (60 - 40) * ((100vw - 300px) / (2000 - 300)));
	font-weight: 100;
	padding: 0rem 3rem 0rem 3rem;
}



/*----------------------------FAQ PAGE CSS------------------------------*/


.faq-content {
  margin: 0;
  padding-left: 3em;
  padding-top:3rem;
  color: black;
}

.faq-question {
  padding: 20px 0;
  border-bottom: 1px dotted #ccc;
  color: black;
}

.panel-title {
  font-size: 21px;
  width: 100%;
  position: relative;
  margin: 0;
  padding: 10px 10px 0 68px;
  display: block;
  cursor: pointer;
  color: black;
}

.panel-content {
  font-size: 20px;
  padding: 0px 14px 0px 0px;
  height: 0;
  color: black;
  z-index: -1;
  position: relative;
  opacity: 0;
  transition: .4s ease;
}

.panel:checked ~ .panel-content{
  height: auto;
  opacity: 1;
  padding: 14px;
  color: black;
}

.plus {
  position: absolute;
  margin-left: 20px;
  margin-top: 4px;
  z-index: 5;
  font-size: 42px;
  line-height: 100%;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
  transition: .2s ease;
}

.panel:checked ~ .plus {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.panel {
  display: none;
}

/*---------------------- Scroll behavior ---------------------*/


.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.reveal {
  position: relative;
  opacity: 0;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
}

.reveal.active {
  opacity: 1;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
}
.active.fade-bottom {
  -webkit-animation: fade-bottom 0.8s  ease-in;
          animation: fade-bottom 0.8s  ease-in;
}
.active.fade-left {
  -webkit-animation: fade-left 0.6s ease-in;
          animation: fade-left 0.6s ease-in;
}
.active.fade-right {
  -webkit-animation: fade-right 0.6s ease-in;
          animation: fade-right 0.6s ease-in;
}
@-webkit-keyframes fade-bottom {
  0% {
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
    opacity: 0;

  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;

  }
}
@keyframes fade-bottom {
  0% {
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
    opacity: 0;

  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;

  }
}
@-webkit-keyframes fade-left {
  0% {
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fade-left {
  0% {
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes fade-right {
  0% {
    -webkit-transform: translateX(100px);
            transform: translateX(100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fade-right {
  0% {
    -webkit-transform: translateX(100px);
            transform: translateX(100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

/*----------------------------*/

.thankyou{
  	text-align: center;
  	display: flex;
  	flex-direction: column;
    background-image: url(/static/media/Contact.f12b92d3.png);
    padding-top: 30px;
    color: black;
    background-size: 100rem;
    margin-top: 80px;

}

.thankyou h4{
  padding: 10rem;
  text-align: center;
  font-size: calc(40px + (90 - 40) * ((100vw - 300px) / (1600 - 300)));
  font-weight: 500;
}

@media only screen and (max-width: 500px) {
  .thankyou{
    	text-align: center;
    	display: flex;
    	flex-direction: column;
      padding-top: 80px;
      color: black;
      background-size: 100rem;
      margin-top: 80px;
      height: 40rem;
  }
  .thankyou h4{
    padding: 5rem;
    text-align: center;
    font-size: calc(40px + (90 - 40) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 500;
  }


}

:root {
  --primary: #fff;
}

.btn {
  padding: 8px 20px;
  border-radius: 7px;
  outline: none;
  border: none;
  cursor: none;
}

.btn--primary {
  background-color: transparent;
  color: #fff;
  padding: 8px 20px;
  border: 1px solid #fff;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
}

.btn--medium {
  padding: 8px 20px;
  font-size: 20px;
}

.btn--large {
  padding: 12px 26px;
  font-size: 20px;
}

.btn--medium:hover,
.btn--large:hover {
  background-color: #fff;
  color: #242424;
  transition: all 0.3s ease-out;
}

